import { createRouter, createWebHistory } from "vue-router";
import ConditionVue from "../views/Condition.vue";
// import Register from "../views/Register.vue";
// import Submit from "../views/Submit.vue";
// import SubmitHistory from "../views/SubmitHistory.vue";
// import Redeem from "../views/Redeem.vue";
// import RedeemHistory from "../views/RedeemHistory.vue";
// import RedeemSuccess from "../views/RedeemSuccess.vue";
// import Condition from "../views/Condition.vue";
// import Winner from "../views/Winner.vue";
// import Success from "../views/Success.vue";
// import Off from "../views/Off.vue";
import HomeVue from "../views/Home.vue";
import OffVue from "../views/Off.vue";
import RegisterVue from "../views/Register.vue";
import SubmitVue from "../views/Submit.vue";
import SubmitHistoryVue from "../views/SubmitHistory.vue";
import SubmitSuccessVue from "../views/SubmitSuccess.vue";
import Topspender from "../views/Topspender.vue";
import WinnerVue from "../views/Winner.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: RegisterVue,
  },

  {
    path: "/register",
    name: "Register",
    component: RegisterVue,
  },
  {
    path: "/submit",
    name: "Submit",
    component: SubmitVue,
  },

  {
    path: "/success",
    name: "SubmitSuccess",
    component: SubmitSuccessVue,
  },

  {
    path: "/condition",
    name: "Condition",
    component: ConditionVue,
  },
  {
    path: "/history",
    name: "SubmitHistory",
    component: SubmitHistoryVue,
  },
  {
    path: "/winner",
    name: "Winner",
    component: WinnerVue,
  },
  {
    path: "/off",
    name: "Off",
    component: OffVue,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
