<template>
  <div>
    <div
      class="bg-green-700 text-white font-bold text-2xl mb-3 py-5 text-center"
    >
      ประกาศรายชื่อผู้โชคดี
    </div>
    <div class="content w-full px-5 xl:w-2/3 lg:w-2/3 md:w-2/3 mx-auto">
      <loading
        v-model:active="isLoading"
        :is-full-page="fullPage"
        color="#00bcb4"
      ></loading>

      <div className="">
        <div class="mt-5"></div>
        <!-- -->
        <div>
          <div>
            <div class="text-white font-bold mb-2">ผู้โชคดีประจำครั้งที่</div>
            <div class="relative flex w-full flex-wrap items-stretch mb-3">
              <span
                class="z-10 h-full absolute text-center text-blue-700 absolute bg-transparent rounded items-center justify-center w-8 pl-3 py-3"
              >
              </span>

              <select
                v-model="winNo"
                placeholder="เลือกวันประกาศผล"
                @change="onWinNoChange"
                class="px-3 py-3 relative bg-white bg-white rounded-2xl border border-gray-300 outline-none focus:outline-none focus:ring w-full pl-5"
              >
                <option value="0">เลือกวันประกาศผล</option>
                <option
                  v-for="(w, index) in winNoList"
                  :value="w.WD_ID"
                  :key="index"
                >
                  ครั้งที่ {{ w.WD_NO }} : วันที่ {{ w.WD_DATE_TH }}
                </option>
              </select>
            </div>
            <!-- -->
          </div>
        </div>
        <div v-if="winDateData !== null">
          <div v-if="winDateData.WD_HAS_WINNER == true">
            <div class="font-extrabold text-center">
              รางวัลครั้งที่ {{ winDateData.WD_NO }}
            </div>
            <div v-for="(w, index) in winDateData.WD_DATA" :key="index">
              <div class="mt-3">
                <div
                  class="bg-green-700 rounded-xl p-5"
                  :class="[w.reward.RW_CODE === 'R1' ? 'mt-60' : 'mt-24']"
                >
                  <img
                    :src="w.reward.RW_PICTURE"
                    class="mx-auto"
                    :class="[w.reward.RW_CODE === 'R1' ? '-mt-60' : '-mt-24']"
                  />

                  <div class="text-white text-center px-5">
                    <div>
                      <div class="text-sm">
                        {{ w.reward.RW_NAME }} <br />มูลค่า
                        {{ w.reward.RW_PRICE }} บาท จำนวน
                        {{ w.number_winner }} รางวัล
                      </div>
                    </div>
                  </div>
                </div>
                <div class="overflow-x-auto rounded-lg relative mt-5">
                  <table
                    class="border-collapse w-full whitespace-no-wrap bg-white table-striped relative"
                  >
                    <thead>
                      <tr class="text-left text-sm bg-green-900">
                        <th
                          class="text-white text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                        >
                          ลำดับ
                        </th>
                        <th
                          class="text-white text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                        >
                          ชื่อ - นามสกุล
                        </th>
                        <th
                          class="text-white text-center sticky text-white top-0 border-b border-gray-200 px-3 py-2 whitespace-nowrap"
                        >
                          เบอร์โทร
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-black text-sm">
                      <tr v-for="(p, index) in w.winner" :key="index">
                        <td
                          class="border-b border-gray-200 text-center py-2 whitespace-nowrap"
                        >
                          {{ index + 1 }}
                        </td>
                        <td
                          class="border-b border-gray-200 py-2 text-center whitespace-nowrap"
                        >
                          {{ p.WC_CUS_FNAME }}
                        </td>
                        <td
                          class="border-b border-gray-200 py-2 pl-3 whitespace-nowrap"
                        >
                          {{ p.WC_CUS_MOBILE_HIDDEN }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div v-if="winDateData.WD_HAS_WINNER == false" class="py-5">
            <div class="text-center mt-10">
              <div class="font-bold text-2xl pt-5 mb-1 text-center">
                ขออภัยค่ะ
              </div>
              <h1 class="text-lg font-extrabold">
                ยังไม่ประกาศรายชื่อผู้โชคดี
              </h1>
              <h2>
                ครั้งที่ {{ winDateData.WD_NO }} (ประจำวันที่
                {{ winDateData.WD_DATE_TH }})
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Service from "../service/service";
export default {
  name: "Winner",
  data() {
    return {
      isLoading: true,
      fullPage: true,
      winType: "1",
      winNo: "0",
      winNoList: null,
      winTypeOk: false,
      winDateData: null,
    };
  },
  components: {
    Loading,
  },
  service: null,
  created() {
    this.service = new Service();
  },
  async mounted() {
    this.isLoading = true;
    this.onWinTypeChange();
    this.isLoading = false;
  },
  methods: {
    async onWinTypeChange() {
      this.winNoList = null;
      this.winNo = "0";
      this.winDateData = null;

      await this.service.getWinnerDate(this.winType).then((res) => {
        console.log("data =", res);
        this.winNoList = res.data;
      });
      // console.log(this.winNoList);
    },
    async onWinNoChange() {
      this.winDateData = null;

      await this.winNoList.forEach((e) => {
        console.log(e.WD_ID, this.winNo);
        if (e.WD_ID === this.winNo) {
          this.winDateData = e;
        }
      });
      console.log(this.winDateData);
    },
  },
};
</script>
